body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.add-device-button {
  position: fixed !important;
  bottom: 1em;
  right: 1em;
}
.pumping-time {
  padding: 15px;
  background-color: #3f51b5;
  border-radius: 20px;
  color: #fff;
  position: fixed !important;
  bottom: 1em;
  left: 1em;
}
.flex-grow {
  flex-grow: 1;
}
.center-align {
  text-align: center;
}
.full-screen {
  width: 100%;
  height: 100%;
}

.center-loading {
  position: fixed;
  width: 200px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-top: -25px; /* Negative half of height. */
  margin-left: -100px; /* Negative half of width. */
  text-align: center;
}
.center {
  margin: auto;
}
.center-loading div {
  margin: auto;
}
.center-loading p {
  text-align: center;
  margin-top: 1em;
}

section {
  margin: auto;
}

.device-list-app-bar button {
  margin-left: 1em;
}
.fetching-dialog-title {
  text-align: center;
}
.fetching-dialog-content {
  text-align: center;
}
.async-op-ok .MuiDialog-paper {
  background-color: #4caf50;
  color: #ffffff;
}

.async-op-fail .MuiDialog-paper {
  background-color: #f44336;
  color: #ffffff;
}
.async-op-fail .MuiDialogContentText-root {
  color: #ffffff;
  margin-top: 1em;
}

.async-op-fail .MuiButton-label {
  color: #ffffff;
}
.main-menu-button {
  margin-left: 0 !important;
}
table .absorbing-column {
  width: 100%;
}
.versions-table {
  margin: auto;
  max-width: 580px;
}
textarea.jsoneditor-text {
  min-height: 500px !important;
}

.googl-login-chapter {
  background-color: #3f50b5;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.googl-login-block {
  margin: 0px 0px 0px 0px;
}

/* picture */
.row {
  padding: 0 16px;
}
.picture {
  max-width: 100%;
  height: auto;
}
/* picture */
.btn {
  text-decoration: none;
}
.picture__loader {
  position: absolute;
  top: 45vh;
  left: 45vw;
}
a { 
  text-decoration: none;
  color: black;
 } 